import { Config } from './types';
export const baseApiUri = process.env.REACT_APP_API_URL || 'https://ajhh5uckgk.eu-west-1.awsapprunner.com/api/v1';

export const config: Config = {
    name: process.env.REACT_APP_COMPANY_NAME || 'Rippling',
    logoUrl: process.env.REACT_APP_COMPANY_LOGO || 'https://imagedelivery.net/YV7tiPr1pNu8eWcEhMhZuA/0137ac34-c137-45fc-d80c-57783a2ddd00/public',
    primaryColor: process.env.REACT_APP_PRIMARY_COLOR || '#fdb71c',
    secondaryColor: process.env.REACT_APP_SECONDARY_COLOR || '#fff',
    thirdColor: process.env.REACT_APP_THIRD_COLOR || '#000',
};
